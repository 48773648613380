.App {
  text-align: center;
  background-image: url("bg.jpg");
  padding-top: 6pt;
  padding-bottom: 6pt;
  /* background-repeat: repeat-y;
  background-size: contain; */
}

.App-logo {
  /* width: 60vmin; */
  max-width: 100vmin;
  pointer-events: none;
}
.App-box {
  /* width: 60vmin; */
  max-width: 75vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.App-link-discord {
  color: #ffffff;
}
.App-link-discord :hover {
  color: #282c34;
}

.App-link-discord-dark {
  color: #464a52;
}
.App-link-discord-dark :hover {
  color: #000000;
}

.App-FAQ {
  background-color: #282c34;
}

.footer {
  min-height: 2vh;
  font-size: calc(8px + 1vmin);
  /* background-color: #312920;
  border-radius: 20pt; */
  font-weight: normal;
  color: #312a20;
}

.canvas-body {
  /* background-color: #e4dabb; */
  background-color: rgba(255, 242, 198, 0.8);
}

.App-content {
  background-color: rgba(0, 0, 0, 0.8);
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 1vmin);
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.App-content-alt {
  background-color: rgba(41, 28, 12, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 1vmin);
  color: rgb(255, 255, 255);
  padding-top: 10px;
  padding-bottom: 10px;
}

.App-content-image {
  height: 40vmin;
  /* max-width: 45vmin; */
  pointer-events: none;
  /* margin-top: 10px;
  margin-bottom: 10px; */
}

h3 {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  /* background-color: black; */
  padding: 4px 4px 4px 4px;
  /* color: rgb(212, 0, 212); */
  color: #fbd761;
  font-weight: bold;
}

h3.faq {
  background-color: #312920;
  border-radius: 20pt;
  font-size: calc(12px + 1vmin);
}

.team {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.roadmap {
  background-color: #fbd761;
  border-radius: 20pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("pole.png");
  background-repeat: repeat-y;
  background-position-x: center;
}
.roadmap-card {
  border-radius: 20pt;
  width: 80vmin;
  justify-content: left;
}
.roadmap-card-title {
  font-size: calc(10px + 2vmin);
  background-color: #61dafb;
  border-radius: 10pt;
  color: #282c34;
}

li.unchecked {
  list-style-image: url("icon-unchecked.png");
  font-size: calc(10px + 1vmin);
}

li.checked {
  list-style-image: url("icon-checked.png");
  background-color: rgb(241, 209, 122);
  border-radius: 10pt;
  color: black;
  font-size: calc(10px + 1vmin);
}

.menu-toggle-button {
  border: 0px;
}

.menu-toggle-button :active {
  border: 0px;
}

.highlight {
  font-weight: bold;
  font-variant: small-caps;
  color: rgb(241, 209, 122);
}

code a {
  color: rgb(234, 0, 255);
}

code a:hover {
  color: rgb(157, 1, 204);
}

.copyright {
  font-weight: 700;
  color: rgba(22, 14, 4, 0.8);
}

.strikethru {
  text-decoration: line-through;
}

a.magiceden {
  font-size: calc(7px + 1vmin);
  color: rgb(255, 255, 255);
}
a:hover.magiceden {
  font-size: calc(7px + 1vmin);
  color: rgb(100, 40, 155);
}
